window.requestAnimFrame = (function(callback) {
  return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame ||
  function(callback) {
    window.setTimeout(callback, 1000 / 60);
  };
})();

function initBalls() {
  balls = [];

  var blue = '#ffffff';
  var red = '#EF2B36';
  var yellow = '#ffffff';
  var green = '#ffffff';

  // Z
  balls.push(new Ball(25, 68, 0, 0, blue));
  balls.push(new Ball(37, 69, 0, 0, blue));
  balls.push(new Ball(51, 68, 0, 0, blue));
  balls.push(new Ball(65, 68, 0, 0, blue));
  balls.push(new Ball(77, 69, 0, 0, blue));
  balls.push(new Ball(69, 83, 0, 0, blue));
  balls.push(new Ball(60, 93, 0, 0, blue));
  balls.push(new Ball(50, 103, 0, 0, blue));
  balls.push(new Ball(40, 114, 0, 0, blue));
  balls.push(new Ball(30, 125, 0, 0, blue));
  balls.push(new Ball(44, 125, 0, 0, blue));
  balls.push(new Ball(58, 125, 0, 0, blue));
  balls.push(new Ball(70, 127, 0, 0, blue));
  balls.push(new Ball(80, 124, 0, 0, blue));

  // U
  balls.push(new Ball(110, 68, 0, 0, green));
  balls.push(new Ball(111, 83, 0, 0, green));
  balls.push(new Ball(111, 96, 0, 0, green));
  balls.push(new Ball(112, 109, 0, 0, green));
  balls.push(new Ball(118, 122, 0, 0, green));
  balls.push(new Ball(130, 125, 0, 0, green));
  balls.push(new Ball(144, 123, 0, 0, green));
  balls.push(new Ball(154, 114, 0, 0, green));
  balls.push(new Ball(158, 100, 0, 0, green));
  balls.push(new Ball(160, 85, 0, 0, green));
  balls.push(new Ball(161, 70, 0, 0, green));

  // K
  balls.push(new Ball(194, 11, 0, 0, yellow));
  balls.push(new Ball(195, 25, 0, 0, yellow));
  balls.push(new Ball(195, 39, 0, 0, yellow));
  balls.push(new Ball(195, 53, 0, 0, yellow));
  balls.push(new Ball(195, 68, 0, 0, yellow));
  balls.push(new Ball(196, 84, 0, 0, yellow));
  balls.push(new Ball(195, 99, 0, 0, yellow));
  balls.push(new Ball(196, 113, 0, 0, yellow));
  balls.push(new Ball(195, 127, 0, 0, yellow));
  balls.push(new Ball(205, 95, 0, 0, yellow));
  balls.push(new Ball(216, 85, 0, 0, yellow));
  balls.push(new Ball(228, 75, 0, 0, yellow));
  balls.push(new Ball(238, 68, 0, 0, yellow));
  balls.push(new Ball(214, 105, 0, 0, yellow));
  balls.push(new Ball(225, 115, 0, 0, yellow));
  balls.push(new Ball(233, 125, 0, 0, yellow));
  balls.push(new Ball(244, 133, 0, 0, yellow));

  // A
  balls.push(new Ball(276, 75, 0, 0, blue));
  balls.push(new Ball(290, 68, 0, 0, blue));
  balls.push(new Ball(304, 70, 0, 0, blue));
  balls.push(new Ball(316, 69, 0, 0, blue));
  balls.push(new Ball(326, 81, 0, 0, blue));
  balls.push(new Ball(328, 97, 0, 0, blue));
  balls.push(new Ball(329, 113, 0, 0, blue));
  balls.push(new Ball(329, 125, 0, 0, blue));
  balls.push(new Ball(334, 133, 0, 0, blue));
  balls.push(new Ball(315, 100, 0, 0, blue));
  balls.push(new Ball(300, 100, 0, 0, blue));
  balls.push(new Ball(286, 105, 0, 0, blue));
  balls.push(new Ball(277, 118, 0, 0, blue));
  balls.push(new Ball(282, 132, 0, 0, blue));
  balls.push(new Ball(296, 133, 0, 0, blue));
  balls.push(new Ball(312, 129, 0, 0, blue));

  // A
  var oOffset = 89;
  balls.push(new Ball(oOffset + 276, 73, 0, 0, green));
  balls.push(new Ball(oOffset + 290, 66, 0, 0, green));
  balls.push(new Ball(oOffset + 304, 68, 0, 0, green));
  balls.push(new Ball(oOffset + 316, 67, 0, 0, green));
  balls.push(new Ball(oOffset + 326, 79, 0, 0, green));
  balls.push(new Ball(oOffset + 328, 95, 0, 0, green));
  balls.push(new Ball(oOffset + 329, 111, 0, 0, green));
  balls.push(new Ball(oOffset + 329, 123, 0, 0, green));
  balls.push(new Ball(oOffset + 334, 131, 0, 0, green));
  balls.push(new Ball(oOffset + 315, 98, 0, 0, green));
  balls.push(new Ball(oOffset + 300, 98, 0, 0, green));
  balls.push(new Ball(oOffset + 286, 103, 0, 0, green));
  balls.push(new Ball(oOffset + 277, 116, 0, 0, green));
  balls.push(new Ball(oOffset + 282, 130, 0, 0, green));
  balls.push(new Ball(oOffset + 296, 131, 0, 0, green));
  balls.push(new Ball(oOffset + 312, 127, 0, 0, green));

  // D
  balls.push(new Ball(504, 11, 0, 0, red));
  balls.push(new Ball(504, 25, 0, 0, red));
  balls.push(new Ball(505, 39, 0, 0, red));
  balls.push(new Ball(506, 55, 0, 0, red));
  balls.push(new Ball(506, 70, 0, 0, red));
  balls.push(new Ball(505, 84, 0, 0, red));
  balls.push(new Ball(507, 100, 0, 0, red));
  balls.push(new Ball(509, 115, 0, 0, red));
  balls.push(new Ball(509, 128, 0, 0, red));
  balls.push(new Ball(492, 70, 0, 0, red));
  balls.push(new Ball(477, 72, 0, 0, red));
  balls.push(new Ball(462, 79, 0, 0, red));
  balls.push(new Ball(453, 95, 0, 0, red));
  balls.push(new Ball(454, 111, 0, 0, red));
  balls.push(new Ball(462, 125, 0, 0, red));
  balls.push(new Ball(479, 130, 0, 0, red));
  balls.push(new Ball(495, 128, 0, 0, red));

  // E
  balls.push(new Ball(554, 101, 0, 0, red));
  balls.push(new Ball(564, 98, 0, 0, red));
  balls.push(new Ball(579, 95, 0, 0, red));
  balls.push(new Ball(577, 83, 0, 0, red));
  balls.push(new Ball(571, 78, 0, 0, red));
  balls.push(new Ball(558, 77, 0, 0, red));
  balls.push(new Ball(546, 82, 0, 0, red));
  balls.push(new Ball(542, 93, 0, 0, red));
  balls.push(new Ball(540, 108, 0, 0, red));
  balls.push(new Ball(548, 120, 0, 0, red));
  balls.push(new Ball(558, 127, 0, 0, red));
  balls.push(new Ball(570, 130, 0, 0, red));
  balls.push(new Ball(578, 125, 0, 0, red));

  // V
  balls.push(new Ball(608, 77, 0, 0, red));
  balls.push(new Ball(613, 85, 0, 0, red));
  balls.push(new Ball(618, 95, 0, 0, red));
  balls.push(new Ball(623, 105, 0, 0, red));
  balls.push(new Ball(625, 117, 0, 0, red));
  balls.push(new Ball(631, 129, 0, 0, red));
  balls.push(new Ball(638, 120, 0, 0, red));
  balls.push(new Ball(642, 112, 0, 0, red));
  balls.push(new Ball(648, 100, 0, 0, red));
  balls.push(new Ball(653, 88, 0, 0, red));
  balls.push(new Ball(659, 79, 0, 0, red));

  return balls;
}
function getMousePos(canvas, evt) {
  // get canvas position
  var obj = canvas;
  var top = 0;
  var left = 0;
  while(obj.tagName != 'BODY') {
    top += obj.offsetTop;
    left += obj.offsetLeft;
    obj = obj.offsetParent;
  }

  // return relative mouse position
  var mouseX = evt.clientX - left + window.pageXOffset;
  var mouseY = evt.clientY - top + window.pageYOffset;
  return {
    x: mouseX,
    y: mouseY
  };
}
function updateBalls(canvas, balls, timeDiff, mousePos) {
  var context = canvas.getContext('2d');
  var collisionDamper = 0.3;
  var floorFriction = 0.0005 * timeDiff;
  var mouseForceMultiplier = 1 * timeDiff;
  var restoreForce = 0.002 * timeDiff;

  for(var n = 0; n < balls.length; n++) {
    var ball = balls[n];
    // set ball position based on velocity
    ball.y += ball.vy;
    ball.x += ball.vx;

    // restore forces
    if(ball.x > ball.origX) {
      ball.vx -= restoreForce;
    }
    else {
      ball.vx += restoreForce;
    }
    if(ball.y > ball.origY) {
      ball.vy -= restoreForce;
    }
    else {
      ball.vy += restoreForce;
    }

    // mouse forces
    var mouseX = mousePos.x;
    var mouseY = mousePos.y;

    var distX = ball.x - mouseX;
    var distY = ball.y - mouseY;

    var radius = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2));

    var totalDist = Math.abs(distX) + Math.abs(distY);

    var forceX = (Math.abs(distX) / totalDist) * (1 / radius) * mouseForceMultiplier;
    var forceY = (Math.abs(distY) / totalDist) * (1 / radius) * mouseForceMultiplier;

    if(distX > 0) {// mouse is left of ball
      ball.vx += forceX;
    }
    else {
      ball.vx -= forceX;
    }
    if(distY > 0) {// mouse is on top of ball
      ball.vy += forceY;
    }
    else {
      ball.vy -= forceY;
    }

    // floor friction
    if(ball.vx > 0) {
      ball.vx -= floorFriction;
    }
    else if(ball.vx < 0) {
      ball.vx += floorFriction;
    }
    if(ball.vy > 0) {
      ball.vy -= floorFriction;
    }
    else if(ball.vy < 0) {
      ball.vy += floorFriction;
    }

    // floor condition
    if(ball.y > (canvas.height - ball.radius)) {
      ball.y = canvas.height - ball.radius - 2;
      ball.vy *= -1;
      ball.vy *= (1 - collisionDamper);
    }

    // ceiling condition
    if(ball.y < (ball.radius)) {
      ball.y = ball.radius + 2;
      ball.vy *= -1;
      ball.vy *= (1 - collisionDamper);
    }

    // right wall condition
    if(ball.x > (canvas.width - ball.radius)) {
      ball.x = canvas.width - ball.radius - 2;
      ball.vx *= -1;
      ball.vx *= (1 - collisionDamper);
    }

    // left wall condition
    if(ball.x < (ball.radius)) {
      ball.x = ball.radius + 2;
      ball.vx *= -1;
      ball.vx *= (1 - collisionDamper);
    }
  }
}
function Ball(x, y, vx, vy, color) {
  this.x = x;
  this.y = y;
  this.vx = vx;
  this.vy = vy;
  this.color = color;
  this.origX = x;
  this.origY = y;
  this.radius = 10;
}
function animate(canvas, balls, lastTime, mousePos) {
  var context = canvas.getContext('2d');

  // update
  var date = new Date();
  var time = date.getTime();
  var timeDiff = time - lastTime;
  updateBalls(canvas, balls, timeDiff, mousePos);
  lastTime = time;

  // clear
  context.clearRect(0, 0, canvas.width, canvas.height);

  // render
  for(var n = 0; n < balls.length; n++) {
    var ball = balls[n];
    context.beginPath();
    context.arc(ball.x, ball.y, ball.radius, 0, 2 * Math.PI, false);
    context.fillStyle = ball.color;
    context.fill();
  }

  // request new frame
  requestAnimFrame(function() {
    animate(canvas, balls, lastTime, mousePos);
  });
}
var canvas = document.getElementById('myCanvas');
var balls = initBalls();
var date = new Date();
var time = date.getTime();
/*
 * set mouse position really far away
 * so the mouse forces are nearly obsolete
 */
var mousePos = {
  x: 9999,
  y: 9999
};

canvas.addEventListener('mousemove', function(evt) {
  var pos = getMousePos(canvas, evt);
  mousePos.x = pos.x;
  mousePos.y = pos.y;
});

canvas.addEventListener('mouseout', function(evt) {
  mousePos.x = 9999;
  mousePos.y = 9999;
});
animate(canvas, balls, time, mousePos);
